<template>
    <div class="mainView">
        <div class="filterContainer">
            <b-button class="showFilterButton" v-b-toggle.sidebar-1><font-awesome-icon icon="fa-solid fa-filter" /></b-button>
            <b-form-input class="filterText" v-model="filteredText" placeholder="Search..."></b-form-input>
            <b-sidebar id="sidebar-1" title="Filters" bg-variant="dark" text-variant="light" sidebar-class="border-right border-secondary" shadow>
                <filters
                :isSidebar=true
                parentKey="filterSelected"
                @filterSelected="updateFilters"></filters>
                <b-form-rating class="ratings" style="margin-left: 0.75em" id="rating-inline" inline @change="ratingsFilter" v-model="filterRating"></b-form-rating>
            </b-sidebar>
        </div>
        <div class="cardContainer" v-if="this.recipes.length > 0">
            <b-card v-for="items in recipes" no-body :key="items[1].id" @click="openSelectedRecipe(items)" class="overflow-hidden recipeCard">
                <b-row no-gutters>
                <b-col md="6" v-if="items[1].image">
                    <b-card-img  :src="`data:image/png;base64,${items[1].image}`" alt="Image" class="rounded-0 recipeImage"></b-card-img>
                </b-col>
                <b-col md="6">
                    <b-card-body :title="items[1].name">
                    <b-card-text>
                        <div class="cardNotes" v-html="items[1].notes"></div>
                    </b-card-text>
                    </b-card-body>
                </b-col>
                </b-row>
                <template #footer>
                    <div>
                        <b-row>
                            <b-col sm>
                                <b-form-rating class="ratings" id="rating-inline" inline disabled :value="items[1].rating"></b-form-rating>
                            </b-col>
                                <div class="timingContainer">
                                    <p style="margin-right: 10px;"><font-awesome-icon icon="fa-regular fa-clock" /> {{ items[1].prep }}m</p>
                                    <p><font-awesome-icon icon="fa-solid fa-fire" /> {{ items[1].cook }}m</p>
                                </div>
                        </b-row>                        
                    </div>
                </template>
            </b-card>
        </div>
        <b-modal 
        id="recipeModal" 
        title="RecipeName"
        size="lg"
        @hidden="clearSelectedRecipe"
        class="recipeModal">
            <recipeDetails
            :recipe="selectedRecipe"></recipeDetails>
            <template #modal-title>
                <h5>{{ selectedRecipe[1].name }} <br> <span class="originText">Origin: {{ selectedRecipe[1].origin }} <br>Calories: {{ selectedRecipe[1].calories }}</span></h5>
            </template>
            <template #modal-footer>
                <b-form-rating class="floatLeft" id="rating-inline" inline @change="updateRating" v-model="selectedRecipe[1].rating"></b-form-rating>
                <b-button @click="$bvModal.show('modal-select-share')"><font-awesome-icon icon="fa-solid fa-share-nodes" /></b-button> 
                <p style="margin-right: 10px;"><font-awesome-icon icon="fa-regular fa-clock" /> {{ selectedRecipe[1].prep }}m</p>
                <p><font-awesome-icon icon="fa-solid fa-fire" /> {{ selectedRecipe[1].cook }}m</p>
            </template>
        </b-modal>
        <b-modal
        id="modal-select-share">
            <selectShareUser
            @shareEmail="updateShareEmail">
            </selectShareUser>
            <template #modal-footer>
                <b-button @click="$bvModal.hide('modal-select-share'); shareWith()">
                    Share
                </b-button>
            </template>
        </b-modal>
  </div>
  
</template>
<script>

import recipeDetails from '../components/recipeDetails.vue'
import selectShareUser from '../components/selectShareUser.vue'
import filters from '../components/filtersOptions.vue' 
import { db } from '../firebase/init.js'
import { doc, query, where, collection, getDocs, updateDoc, addDoc } from 'firebase/firestore'

export default {
    name: "mainView",
    components: {
        recipeDetails,
        filters,
        selectShareUser,
    },
  data() {
    return {
        recipes: [],
        selectedRecipe: null,
        selected: [], // Must be an array reference!
        options: [
          { text: 'u600', value: 'list' },
          { text: 'test1', value: 'test1' },
          { text: 'Pineapple', value: 'of' },
          { text: 'Grape', value: 'grape' }
        ],
        filteredText: '',
        tempRecipeList: [],
        filterRating: 0,
        shareEmail: null,
    }
  },
  created() {
    // if (!this.$store.state.user.loggedIn) {
    //     this.$router.push('/login')
    // }
    this.updateRecipeList()
    console.log('TEST', this.recipes);
  },
  watch: {
    selected() {
        this.filterRecipes();
    },
    filteredText() {
        this.searchRecipes();
    }
  },
  methods: {
    updateShareEmail(email) {
        this.shareEmail = email;
    },
    async shareWith() {
        const docRef = collection(db, 'users')
        const email = this.shareEmail
        // eslint-disable-next-line no-unused-vars
        const q = await getDocs(query(docRef, where('email', '==', email)))
         .then(async (userDocs) => {
            console.log(userDocs.docs[0]);
            const uid = userDocs.docs[0]._key.path.segments[6];
            const colRef = collection(db, 'users', uid, 'shared');
            const dataObj = this.selectedRecipe[1];
            // eslint-disable-next-line no-unused-vars
            const share = await addDoc(colRef, dataObj);
         });


    },
    updateFilters(filters) {
        this.selected = filters;
    },
    searchRecipes() {
        this.recipes = [];
        this.tempRecipeList.forEach((i) => {
            if (i[1].name.toLowerCase().includes(this.filteredText.toLowerCase())) {
                this.recipes.push(i);
            }
        })
    },
    async ratingsFilter() {
        await this.updateRecipeList();
        console.log(this.recipes, this.tempRecipeList);
        for (var i = this.recipes.length - 1; i >= 0; i--) {
            if (this.recipes[i][1].rating < this.filterRating) { 
                this.recipes.splice(i, 1);
            }
        }
    },
    async filterRecipes() {
        console.log(this.selected)
        if (this.selected.length > 0) {
            const uid = this.$store.state.user.data.uid;
            const docRef = collection(db, 'users', uid, 'recipes')
            const q = await getDocs(query(docRef, where('categories', 'array-contains-any', this.selected)));
            this.recipes = [];

            q.forEach((doc) => {
                const ids = doc._key.path.segments[8];
                this.recipes.push([ids, doc.data()]);
                console.log(this.recipes);
            })
        } else {
            this.updateRecipeList()
        }
    },
    async updateRating() {
        console.log(this.selectedRecipe)
        const uid = this.$store.state.user.data.uid;
        const docRef = doc(db, 'users', uid, 'recipes', this.selectedRecipe[0]);
        // eslint-disable-next-line no-unused-vars
        const updateRating = await updateDoc(docRef, {
            rating: this.selectedRecipe[1].rating
        });
    },
    clearSelectedRecipe() {
        this.selectedRecipe = null;
    },
    openSelectedRecipe(items) {
        this.selectedRecipe = items;
        this.$bvModal.show('recipeModal');
    },
    async updateRecipeList() {
        this.recipes = [];
        const uid = await this.$store.state.user.data.uid;
        const updateRecipes = await getDocs(query(collection(db, 'users', uid, 'recipes')));

        updateRecipes.forEach((doc) => {
            console.log('doc', doc);
            const ids = doc._key.path.segments[8];
            this.recipes.push([ids, doc.data()]);
        })
        this.tempRecipeList = this.recipes;
    },
  }
}
</script>

<style>
.card-footer {
    border-top: 1px solid #EEEEEE;
}
</style>

<style scoped>
.recipeImage {
    cursor: pointer;
    max-height: 200px;
}

.recipeCard {
    max-width: 500px; 
    background-color: #222831 !important;
    color: #EEEEEE;
    margin: 10px;
    border: 1px solid #EEEEEE;
}
.floatLeft {
    position: absolute;
    left: 30px;
}

.originText {
    font-size: small;
    font-style: italic;
    color: gray;
}

.cardContainer {
  margin: 1em;
  margin-bottom: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.filters {
    margin-top: 2em;
}

.filterContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.filterText {
    width: 20em;
    display: flex;
    margin-top: 1.5em;
    
}

.showFilterButton {
    margin-top: 1.5em;
    margin-right: 1em;
}

.timingContainer {
        display: flex;
}

.ratings {
    background-color: #222831 !important;
    color: #FD7014 !important;
}

.cardNotes {
    max-height: 4.1em;
    overflow-y: auto;
}

@media screen and (max-width: 480px) {
    .timingContainer {
        margin-left: 1em;
        margin-top: 10px;
        display: flex;
    }
    .cardNotes {
        max-height: 10em;
        overflow-y: auto;
    }   
}
@media screen and (max-width: 1200px) {
    .recipeImage {
        height: auto !important;
        max-height: none !important;
    }
    .cardContainer {
        display: block;
    }
    .recipeCard {
        display: block;
        margin: auto !important;
        margin-bottom: 10px !important;
    }
    .filterText {
        width: 18em;
    }
}

</style>
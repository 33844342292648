<template>
    <div>
        <b-container style="display: flex; justify-content: center;">
            <b-card no-body class="credentialCard" >
                <b-card-header header-tag="nav">
                <b-nav card-header tabs>
                    <b-nav-item @click="navClicked = !navClicked" :active="navClicked">Sign Up</b-nav-item>
                    <b-nav-item @click="navClicked = !navClicked" :active="!navClicked">Login</b-nav-item>
                </b-nav>
                </b-card-header>

                <b-card-body class="text-center">
                    <b-form>
                        <p class="loginErrorMessage" v-show="loginError">Invalid username or password. Please try again</p>
                        <b-input v-if="navClicked" type="text" placeholder="Name" v-model="user.name" :class="{ 'mb-2':  true, 'is-invalid': loginError && user.name.length < 2}" name="name" id="name" autocomplete="name"></b-input>
                        <b-input type="email" placeholder="Email" v-model="user.email" :class="{ 'mb-2':  true, 'is-invalid': loginError}" name="email" id="email" autocomplete="email"></b-input>
                        <b-input type="password" placeholder="Password" v-model="user.password" :class="{ 'mb-2':  true, 'is-invalid': loginError}" name="password" id="password" autocomplete="current-password"></b-input>
                        <b-button v-if="navClicked" @click="register" class="submitButton" :disabled="!fieldsFilled" variant="primary">Sign Up</b-button>
                        <b-button v-if="!navClicked" @click="login" class="submitButton" variant="primary">Login</b-button>
                    </b-form>
                </b-card-body>
            </b-card>
        </b-container>
    </div>
</template>
<script>

export default {
    name: "authenticationComponent",
    components: {
    },
  data() {
    return {
        navClicked: true,
        user: {
            name: null,
            email: null,
            password: null,
        },
        loginError: false,
        fieldsFilled: false,
    }
  },
  watch: {
    user: {
        deep: true,
        handler() {
            if ((this.user.name !== null && this.user.name !== '') && (this.user.email !== null && this.user.email !== '') && (this.user.password !== null && this.user.password !== '')) {
                this.fieldsFilled = true;
            } else {
                this.fieldsFilled = false;
            }
        }
    }
  },
  created() {
    if (this.profile) {
        this.$router.push('/')
    }
  },
  computed: {
    profile() {
      return this.$store.state.user.data;
    },
  },
  methods: {
    register() {
        this.$store.dispatch('register', {
            email: this.user.email,
            password: this.user.password,
            name: this.user.name
        }).then(() => {
            console.log('successfully registered');
            this.loginError = false;
            this.$router.push('/createRecipe')
        }).catch((error) => {
            console.log(error);
            this.loginError = true;
        })
    },
    login() {
        this.$store.dispatch('logIn', {
            email: this.user.email,
            password: this.user.password,
        }).then((result) => {
            console.log(result)
            this.loginError = false;
            console.log('successfully logged in');
            this.$router.push('/');
            console.log(this.$store.state.user);
        }).catch((error) => {
            console.log(error.code);
            this.loginError = true;
        })
    },
    validateEmail(mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
        return (true)
    }
        return (false)
    }
  }
}
</script>
<style scoped>

.credentialCard {
    margin-top: 10em;
    width: fit-content;
    width: 40%;
}

.submitButton {
    width: 6em;
}

.loginErrorMessage {
    color: red;
}

@media screen and (max-width: 480px) {
.credentialCard {
    margin-top: 5em;
    width: 100%;
}
}

</style>
import Vue from 'vue'
import Router from 'vue-router'
import mainView from '../views/mainView'
import createRecipe from '../views/createRecipe'
import chooseRandom from '../views/chooseRandom'
import appLogin from '../views/appLogin'
import appShare from '../views/appShare'
import store from '../store/store'

Vue.use(Router)

const router = new Router ({
    routes: [
        {
            path: '/',
            name: 'Main',
            component: mainView,
        },
        {
            path: '/createRecipe',
            name: 'createRecipe',
            component: createRecipe,
        },
        {
            path: '/random',
            name: 'random',
            component: chooseRandom,
        },
        {
            path: '/login',
            name: 'login',
            component: appLogin,
        },
        {
            path: '/shared',
            name: 'shared',
            component: appShare,
        }
    ],
})

router.beforeEach((to, from, next) => {
    if (!store.state.user.loggedIn && to.name !== 'login') {
        next({name: 'login'});
    } else {
        next();
    }
})

export default router;
<template>
    <div>
        <b-navbar toggleable="lg" type="dark" class="navBar">
            <b-navbar-brand to="/">Dish Depot</b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item v-if="this.profile" to="/createRecipe">Create Recipe</b-nav-item>
                <b-nav-item v-if="this.profile" to="/random">Randomiser</b-nav-item>
                <b-nav-item v-if="this.profile" to="/shared">Shared</b-nav-item>
                <p class="userIcon" v-if="this.profile !== null"><font-awesome-icon icon="fa-solid fa-circle-user" /> {{ this.profile?.displayName }}</p>
            </b-navbar-nav>
            </b-collapse>
        </b-navbar>
  </div>
</template>
<script>
export default {
    name: "navBar",
  data() {
    return {
    }
  },
  computed: {
    profile() {
      return this.$store.state.user.data;
    }
  }
}
</script>

<style scoped>
.navBar {
    background-color: #393E46;
}

.userIcon {
  position: absolute;
  right: 10px;
  top: 15px;
  color: white;
}

@media screen and (max-width: 480px) {
  .userIcon {
    display: none;
  }
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'
import { auth, db } from '../firebase/init'
import { setDoc, doc } from 'firebase/firestore'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        user: {
            loggedIn: false,
            data: null
        }
    },
    getters: {
        user(state){
            return state.user
        }
    },
    mutations: {
        SET_USER(state, data) {
          state.user.data = data;
          state.user.loggedIn = !state.user.loggedIn;
        }
    },
    actions: {
        async register(context, { email, password, name}){
            const response = await auth.createUserWithEmailAndPassword(email, password)
            if (response) {
                context.commit('SET_USER', response.user);
                response.user.updateProfile({displayName: name});
                // eslint-disable-next-line no-unused-vars
                const docRef = await setDoc(doc(db, 'users', response.user.uid), {
                    email: response.user.email,
                });
            } else {
                throw new Error('Unable to register user')
            }
        },
  
        async logIn(context, { email, password }){
          const response = await auth.signInWithEmailAndPassword(email, password)
          if (response) {
              context.commit('SET_USER', response.user)
          } else {
              throw new Error('login failed')
          }
      },
  
      async logOut(context){
          await auth.signOut(auth)
          context.commit('SET_USER', null)
      },
  
      async fetchUser(context ,user) {
        context.commit("SET_LOGGED_IN", user !== null);
        if (user) {
          context.commit("SET_USER", {
            displayName: user.displayName,
            email: user.email
          });
        } else {
          context.commit("SET_USER", null);
        }
      },
    }
})

// export the store
export default store
<template>
    <b-card title="New Recipe" class="newRecipeContainer">
        <b-card body-class="newRecipeBody">
            <b-row>
                <b-col cols="12" lg="4">
                    <b-row>
                        <b-col cols="10" lg="6">
                            Name:
                            <b-form-input class="recipeInput" v-model="newRecipe.name" placeholder="Recipe Name"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="10" lg="6">
                            Origin:
                            <b-form-input class="recipeInput" v-model="newRecipe.origin" placeholder="Recipe Origin"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="10" lg="6">
                            Cook Time:
                            <b-form-input class="recipeInput" v-model="newRecipe.cook" type="number" min="0"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="10" lg="6">
                            Prep Time:
                            <b-form-input class="recipeInput" v-model="newRecipe.prep" type="number" min="0"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="10" lg="6">
                            Calories:
                            <b-form-input class="recipeInput" v-model="newRecipe.calories" type="number" min="0"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="10" lg="6">
                            Categories:
                            <multiSelect
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                v-model="newRecipe.categories"
                                :options="options"
                                class="multiSelect">
                            </multiSelect>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="10" lg="6">
                            Rating:<br>
                            <b-form-rating id="rating-inline" inline v-model="newRecipe.rating"></b-form-rating>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="5" md="6" lg="8">
                            <!-- Is there a way to do with with blobs instead? -->
                            <vue-base64-file-upload 
                            placeholder="Upload Image (Max 1mb)"
                            class="v1 upload"
                            accept="image/png,image/jpeg"
                            image-class="v1-image"
                            input-class="v1-image"
                            :max-size="customImageMaxSize"
                            @size-exceeded="onSizeExceeded"
                            @file="onFile"
                            @load="onLoad"
                            disable-preview />
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" lg="8">
                    <b-row class="mb-2"> 
                        <b-col cols="12">
                            <!-- <b-form-textarea
                                class="largeInput"
                                id="textarea"
                                v-model="newRecipe.ingredients"
                                placeholder="Enter Ingredients..."
                                rows="3"
                            ></b-form-textarea> -->
                            <vue-editor
                                id="editor1"
                                :editorToolbar="customToolbar"
                                v-model="newRecipe.ingredients"
                                placeholder="Enter Ingredients..."
                            >
                            </vue-editor>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="12">
                            <!-- <b-form-textarea
                                class="largeInput"
                                id="textarea"
                                v-model="newRecipe.notes"
                                placeholder="Enter Notes..."
                                rows="3"
                            ></b-form-textarea> -->
                            <vue-editor
                                id="editor2"
                                :editorToolbar="customToolbar"
                                v-model="newRecipe.notes"
                                placeholder="Enter Notes..."
                            >
                            </vue-editor>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <!-- <b-form-textarea
                                class="largeInput"
                                id="textarea"
                                v-model="newRecipe.steps"
                                placeholder="Enter Steps..."
                                rows="3"
                            ></b-form-textarea> -->
                            <vue-editor
                                id="editor3"
                                :editorToolbar="customToolbar"
                                v-model="newRecipe.steps"
                                placeholder="Enter Steps..."
                            >
                            </vue-editor>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <b-button class="submitButton" @click="submitRecipe">Submit</b-button>
    </b-card>
</template>
<script>

import { db } from '../firebase/init.js'
import { collection, addDoc } from 'firebase/firestore'
import VueBase64FileUpload from 'vue-base64-file-upload';
import { VueEditor } from "vue2-editor";

export default {
    components: {
        VueBase64FileUpload,
        VueEditor,
    },
    data () {
      return {
        value: null,
        newRecipe: {
            name: '',
            origin: '',
            cook: '',
            prep: '',
            categories: [],
            ingredients: '',
            notes: '',
            steps: '',
            rating: 0,
            image: null,
            calories: null,
        },
        options: ['Under600', 'Easy', 'Quick', 'Pork', 'Fish', 'Beef', 'Chicken', 'Veggie', 'Vegan'],
        uploadedFile: null,
        customImageMaxSize: 1, // megabytes
        customToolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
        ],
        testEditor: '',
      }
    },
    created() {
        //   if (!this.$store.state.user.loggedIn) {
        //        this.$router.push('/login')
        //   }
     },   
    methods: {
    onFile(file) {
        console.log(file); // file object
    },
    onLoad(dataUri) {
      console.log(dataUri); // data-uri string
      this.newRecipe.image = dataUri.split(',')[1];
      console.log(this.newRecipe.image)
    },
    onSizeExceeded(size) {
      alert(`Image ${size}Mb size exceeds limits of ${this.customImageMaxSize}Mb!`);
    },
    submitRecipe() {
        this.uploadRecipe();
    },
    async uploadRecipe() {
        const uid = this.$store.state.user.data.uid;
        const colRef = collection(db, 'users', uid, 'recipes');
        const dataObj = this.newRecipe;
        // eslint-disable-next-line no-unused-vars
        const docRef = await addDoc(colRef, dataObj);
        this.newRecipe = {
            name: '',
            origin: '',
            cook: '',
            prep: '',
            categories: [],
            ingredients: '',
            notes: '',
            steps: '',
            rating: 0,
            image: null,
            calories: null,
        };
    }
  },
  watch: {
    testEditor() {
        console.log(this.testEditor);
    }
  }
}
</script>
<style>
  #editor1, #editor2, #editor3 {
    height: 100px;
  }
  .ql-editor {
    height: 100px !important;
    min-height: 100px !important;
  }
</style>
<style scoped>
  .newRecipeContainer {
    background-color: #EEEEEE !important;
    margin: 10px;
    width: 55%;
    margin-left: 22.5%;
  }
  .multiSelect, .recipeInput {
    width: 12em;
  }
  .submitButton {
    background-color: #FD7014;
    border-color: #FD7014;
    margin-top: 10px;
    float: right;

  }
  .upload {
    margin-top: 10px;
  }

@media screen and (max-width: 1190px) {
  .newRecipeContainer {
    width: 95%;
    margin-left: 2.5%;
  }
  .multiSelect, .recipeInput, .largeInput {
    width: 18em;
  }
  #editor1, #editor2, #editor3 {
    height: 100px;
    width: 18em !important;
  }
  .ql-editor {
    height: 100px !important;
  }
}  
</style>
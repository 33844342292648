<template>
    <b-form-checkbox-group
    class="filters"
    v-model="selected"
    :options="options"
    name="buttons-1"
    :stacked="isSidebar"
    size="lg"
    switches
    >
    </b-form-checkbox-group>
</template>
<script>

export default {
    props: {
        parentKey: {
            type: String,
        },
        isSidebar: {
            type: Boolean
        }
    },
     data() {
          return {
               selected: [],
               options: [
               { text: 'Under 600', value: 'Under600' },
               { text: 'Easy', value: 'Easy' },
               { text: 'Quick', value: 'Quick' },
               { text: 'Pork', value: 'Pork' },
               { text: 'Fish', value: 'Fish' },
               { text: 'Beef', value: 'Beef' },
               { text: 'Chicken', value: 'Chicken' },
               { text: 'Veggie', value: 'Veggie' },
               { text: 'Vegan', value: 'Vegan' },
               ],
          }
     },
     watch: {
        selected() {
            this.$emit(this.parentKey, this.selected)
        }
     }
}
</script>
<style scoped>
.filters {
    color: white;
    margin-left: 10px;
}
</style>
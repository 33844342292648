<!-- eslint-disable vue/no-mutating-props -->
<template>
    <b-container>
        <b-row>
            <b-col sm style="margin-top: 1em;">
                <b-card>
                    <b-row>
                        <b-col sm>
                            <h3>Ingredients</h3>
                            <div v-html="recipe[1].ingredients"></div>
                            <br>
                            <br>
                            <span style="display: flex"><h3>Notes</h3>&emsp;<font-awesome-icon style="position: relative; top: 15px; cursor: pointer;" @click="editNotes" :icon="!editInProgress ? 'fa-solid fa-pen-to-square' : 'fa-solid fa-check'" size="xs"/></span>
                            <div v-show="!this.editInProgress" v-html="recipe[1].notes"></div>
                            <vue-editor
                                id="editor2"
                                v-show="this.editInProgress"
                                :editorToolbar="customToolbar"
                                v-model="recipe[1].notes"
                                placeholder="Enter Notes..."
                            >
                            </vue-editor>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col sm style="margin-top: 1em;">
                <b-card>
                    <b-row>
                        <b-col sm>
                            <h3>Steps</h3>
                            <!-- <p class="listFormat">{{ formatDetails(recipe[1].steps) }}</p> -->
                            <div v-html="recipe[1].steps"></div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-card style="margin-top: 1em;">
            <b-badge v-for="item in recipe[1].categories" :key="item.id" variant="secondary" class="categoryPill">{{ item }}</b-badge>
        </b-card>
    </b-container>
</template>
<script>

import { db } from '../firebase/init.js'
import { doc, updateDoc } from 'firebase/firestore'
import { VueEditor } from "vue2-editor";

export default {
  name: "recipeDetails",
  components: {
        VueEditor,
    },
  data() {
    return {
        selectedRecipe: {},
        editInProgress: false,
        customToolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
        ],
    }
  },
  props: {
    recipe: {
        type: Array,
    }
  },
  created() {
    console.log(this.recipe);
    this.selectedRecipe = this.recipe[1];
  },
  methods: {
    formatDetails(detail) {
        var arr = detail.split(",").map(item => item.trim());
        return arr.join("\n");
    },
    async updateNotes() {
        const uid = this.$store.state.user.data.uid;
        const docRef = doc(db, 'users', uid, 'recipes', this.recipe[0]);
        // eslint-disable-next-line no-unused-vars
        const updateNotes = await updateDoc(docRef, {
            notes: this.selectedRecipe.notes
        });
    },
    editNotes() {
        this.editInProgress = !this.editInProgress;
        if (!this.editInProgress) {
            this.updateNotes();
        }
    },
  },
}
</script>
<style>
#editor1, #editor2, #editor3 {
    height: 100px;
}
.ql-editor {
height: 100px !important;
}
</style>
<style scoped>

.categoryPill {
    margin-right: 5px;
    padding: 10px;
}

.listFormat {
    white-space: pre-wrap;
}
@media screen and (max-width: 1190px) {
  #editor1, #editor2, #editor3 {
    height: 100px;
    width: 18em !important;
  }
  .ql-editor {
    height: 100px !important;
  }
} 
</style>

<template>
  <div id="app">
    <navbar></navbar>
    <router-view/>
  </div>
</template>

<script>
import navbar from './components/navBar.vue'


export default {
  name: 'DishDepot',
  components: {
    navbar,
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
html, body {
  height: 100%;
  background-color: #222831;
}

#app {
  background-color: #222831;
  height: 100%;
}
</style>

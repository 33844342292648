<template>
     <div>
          <div class="randomContainer">
               <b-card class="filterCard">
                    <filters
                    parentKey="filterSelected"
                    @filterSelected="updateFilters"></filters>
                    <br>
                    <b-form-rating class="ratings" style="margin-bottom: 10px;" id="rating-inline" v-model="rating" inline></b-form-rating>
                    <br>
                    <b-button class="randomButton" @click="filterRecipes" variant="warning">Choose my recipe!</b-button>
               </b-card>
          </div>
          <b-card v-for="items in recipe" no-body :key="items.id" class="overflow-hidden recipeCard">
               <b-row no-gutters>
               <b-col md="6">
                    <b-card-img @click="openSelectedRecipe(items)" :src="`data:image/png;base64,${items[1].image}`" alt="Image" class="rounded-0 recipeImage"></b-card-img>
               </b-col>
               <b-col md="6">
                    <b-card-body :title="items[1].name">
                    <b-card-text>
                         <div class="cardNotes" v-html="items[1].notes"></div>
                    </b-card-text>
                    </b-card-body>
               </b-col>
               </b-row>
               <template #footer>
                    <div>
                    <b-row>
                         <b-col sm>
                              <b-form-rating class="ratings" id="rating-inline" inline disabled :value="items[1].rating"></b-form-rating>
                         </b-col>
                         <p style="margin-right: 10px;"><font-awesome-icon icon="fa-regular fa-clock" /> {{ items[1].prep }}m</p>
                         <p><font-awesome-icon icon="fa-solid fa-fire" /> {{ items[1].cook }}m</p>
                    </b-row>                        
                    </div>
               </template>
          </b-card>
          <b-modal 
          id="recipeModal" 
          title="RecipeName"
          size="lg">
               <recipeDetails
               :recipe="recipe[0]"></recipeDetails>
               <template #modal-title>
                    <h5>{{ recipe[0][1].name }} <br> <span class="originText">Origin: {{ recipe[0][1].origin }}<br>Calories: {{ recipe[0][1].calories }}</span></h5>
               </template>
               <template #modal-footer>
                    <b-form-rating class="floatLeft" id="rating-inline" inline disabled v-model="recipe[0][1].rating"></b-form-rating>
                    <p style="margin-right: 10px;"><font-awesome-icon icon="fa-regular fa-clock" /> {{ recipe[0][1].prep }}m</p>
                    <p><font-awesome-icon icon="fa-solid fa-fire" /> {{ recipe[0][1].cook }}m</p>
               </template>
          </b-modal>
          <b-alert
               v-model="showBottom"
               class="position-fixed fixed-bottom m-0 rounded-0"
               style="z-index: 2000;"
               variant="warning"
               dismissible
          >
               No recipes matching this filters. Please try again.
          </b-alert>
     </div>
</template>
<script>

import filters from '../components/filtersOptions.vue' 
import { db } from '../firebase/init.js'
import recipeDetails from '../components/recipeDetails.vue'
import { query, where, collection, getDocs } from 'firebase/firestore'

export default {
     components: {
          filters,
          recipeDetails,
     },
     data() {
          return {
               selected: [],
               recipe: [],
               rating: 0,
               showBottom: false,
          }
     },
     created() {
          // if (!this.$store.state.user.loggedIn) {
          //      this.$router.push('/login')
          // }
     },   
     methods: {
          openSelectedRecipe() {
               this.$bvModal.show('recipeModal');
               console.log(this.recipe[0][1]);
          },
          updateFilters(filters) {
               this.selected = filters;
               console.log(this.selected)
          },
          async filterRecipes() {
               this.recipes = [];
               this.recipe = [];
               let q = null;
               console.log(this.selected)
               const uid = this.$store.state.user.data.uid;
               let docRef = collection(db, 'users', uid, 'recipes')
               if (this.selected.length > 0) {
                    q = await getDocs(query(docRef, where('categories', 'array-contains-any', this.selected)));
               } else {
                    q = await getDocs(query(docRef));
               }

               q.forEach((doc) => {
                    const ids = doc._key.path.segments[8];
                    this.recipes.push([ids, doc.data()]);
               })
               for (let x in this.recipes) {
                    console.log(x, this.recipes[x][1].rating)
                    if (this.recipes[x][1].rating  < this.rating) {
                         this.recipes.splice(x, 1);
                    }
               }
               if (this.recipes.length > 0) {
                    const ranNum = Math.floor(Math.random() * this.recipes.length);
                    this.recipe = [];
                    this.recipe.push(this.recipes[ranNum]);
                    console.log(this.recipe)
                    this.showBottom = false;
               } else {
                    this.showBottom = true;
               }
          }
     },
}
</script>
<style scoped>
.randomContainer {
     margin: 1em;
     margin-bottom: 1em;
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
}

.filterCard {
     background-color: #393E46;
     border-radius: 10px;
     text-align: center;
}
.filters {
     margin: 1em 0 1em 0;
}

.floatLeft {
    position: absolute;
    left: 30px;
}

.recipeCard {
    max-width: 500px; 
    background-color: #222831 !important;
    color: #EEEEEE;
    margin: auto !important;
    margin-top: 1em !important;
    margin: 10px;
    border: 1px solid #FD7014;
}

.ratings {
     background-color: #222831 !important;
     color: #FD7014 !important;
}

.originText {
    font-size: small;
    font-style: italic;
    color: gray;
}

.cardNotes {
    max-height: 4.1em;
    overflow-y: auto;
}

@media screen and (max-width: 480px) {
    .timingContainer {
        margin-left: 1em;
        margin-top: 10px;
        display: flex;
    }
    .cardNotes {
          max-height: 10em;
          overflow-y: auto;
     }
}
@media screen and (max-width: 1200px) {
    .recipeImage {
        height: auto !important;
    }
    .cardContainer {
        display: block;
    }
    .recipeCard {
        display: block;
        margin: auto !important;
    }
    .showFilterButton {
        margin-left: 0.5em;
    }
    .filterText {
        width: 250px;
    }
}
</style>
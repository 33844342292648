<template>
    <div>
        <b-input type="email" v-model="shareEmail" placeholder="Enter email address"></b-input>
    </div>
</template>
<script>

export default {

    data() {
        return {
            shareEmail: null,
        }
    },
    watch: {
        shareEmail() {
            this.$emit('shareEmail', this.shareEmail);
        }
    }
}
</script>
<style></style>
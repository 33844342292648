import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/messaging';
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
firebase.initializeApp({
  apiKey: "AIzaSyDc0y0BG5hjpDVA7sxliafy-jNRuLTzb0k",
  authDomain: "recipebox-241bf.firebaseapp.com",
  projectId: "recipebox-241bf",
  storageBucket: "recipebox-241bf.appspot.com",
  messagingSenderId: "977587523955",
  appId: "1:977587523955:web:80b178708cf538868a7506",
  measurementId: "G-5PS7KV7YNC"
});

const auth = firebase.auth();
const db = firebase.firestore();

export { db, auth };